import "./shared.scss";
import React, { useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import $ from "jquery";
import Markdown from "marked-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import {
  setCookieConsentPersonalization,
  saveCookieConsent,
  setCookieConsentAnalytics,
} from "../js/cookieConsent";

function Cookies(data) {
  const pageData = data.data.allStrapiPages.nodes.filter((obj) => {
    return obj.URL === "cookies";
  });

  useEffect(() => {
    if (document.title !== pageData[0].Title + " - Vímto") {
      document.title = pageData[0].Title + " - Vímto";
    }
  });

  function ulozitNastaveni() {
    saveCookieConsent(
      $("#personalization").prop("checked"),
      $("#analytics").prop("checked")
    );
    $("#message")
      .html('<div class="well green">Nastavení bylo uloženo.</div>')
      .show()
      .delay(2000)
      .fadeOut(400);
    $("#cookieConsent").fadeOut(400);
  }

  return (
    <div className="App">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-1070604381"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-1070604381');`}
        </script>
      </Helmet>
      <Header></Header>
      <div className="App-content">
        <div id="cookieConsentEditation">
          <div>
            Na webových stránkách Scio využíváme soubory cookies. Tyto soubory
            lze rozdělit do tří skupin:
          </div>
          <br />
          <div className="row">
            <div className="left-side">
              <b>Cookies nezbytné pro fungování webu</b>
              <br />
              (bez těchto cookies by webové stránky nemohly správně fungovat)
            </div>
            <div className="right-side">
              <input type="checkbox" checked="checked" disabled="disabled" />
              <br />
              nelze vypnout
            </div>
          </div>
          <div className="row" style={{ marginTop: "15px" }}>
            <div className="left-side">
              <b>Personalizace podle chování návštěvníka stránek</b>
              <br />
              (personalizace je prováděna podle zobrazených stránek a
              zobrazovaný obsah je pak přizpůsoben tomu, co návštěvníka zajímá)
            </div>
            <div className="right-side">
              <input
                type="checkbox"
                name="personalization"
                id="personalization"
                defaultChecked={true}
                onChange={(e) =>
                  setCookieConsentPersonalization(e.target.checked)
                }
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "25px" }}>
            <div className="left-side">
              <b>Cookies třetích stran</b>
              <br />
              (souvisí s implementací některých prvků do webových stránek;
              cookies využívané např. sociálními sítěmi pro úpravu zobrazovaných
              reklam)
            </div>
            <div className="right-side">
              <input
                type="checkbox"
                name="analytics"
                id="analytics"
                defaultChecked={true}
                onChange={(e) => setCookieConsentAnalytics(e.target.checked)}
              />
            </div>
          </div>
          <div className="row">
            <div className="left-side" style={{ paddingTop: "20px" }}>
              Změny nastavení se projeví pouze pro prohlížeč a zařízení, které
              právě používáte.
            </div>
            <div>
              <input
                style={{ border: "none" }}
                type="submit"
                className="button-hover-effect header-container-account-button-register"
                value="Uložit nastavení"
                onClick={() => ulozitNastaveni()}
              />
            </div>
          </div>
          <div id="message" className="save-message"></div>
        </div>
        <Markdown>{pageData[0].PageText}</Markdown>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default () => (
  <StaticQuery
    query={graphql`
      query CookiesQuery {
        allStrapiPages {
          nodes {
            PageText
            Title
            URL
            id
          }
        }
      }
    `}
    render={(data) => <Cookies data={data} />}
  />
);
